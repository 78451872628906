require('./bootstrap')
require('bootstrap/dist/js/bootstrap')
require('bootstrap/js/dist/modal')
require('./helper')

import Homepage from './modules/homepage'
import SwiperSlide from './modules/swiper'

$(document).ready(function() {
    new Homepage()  
    new SwiperSlide()
    AOS.init({
        disable: function() {
          var maxWidth = 481;
          return window.innerWidth < maxWidth;
        }
      });
})