const Helpers = {
    autoHeight: function (element, width = -1, min = false) {
        const setHeight = (element) => {
            try {
                const elements = $(element)
                let heights = []
                elements.each((index, el) => {
                    heights.push($(el).height())
                })
                console.log(heights);
                console.log(Math.max(...heights));
                elements.css('height', 'calc(' + Math.max(...heights) + 'px + 1rem + 1.5rem )')
            } catch (error) {
                console.log('error', error);
            }
        }
        if (width == -1 || (min ? $(window).width() <= width : $(window).width() >= width)) {
            $(document).ready(function () {
                setHeight(element)
            }).resize(function () {
                setHeight(element)
            })
        }
    },
}

$(document).ready(function () {
    Helpers.autoHeight('.latestnewsSwiper .swiper-slide .post-item .post-content ', 1024)
    $('.icon-menu-mb').on('click',function(){
        if($('.content-menu-mobie').hasClass('active')){
          $('.content-menu-mobie').removeClass('active');
        }else{
          $('.content-menu-mobie').addClass('active');
        }
     });
     $('.close-menu-mobie').on('click',function(){
        $('.content-menu-mobie').removeClass('active');
     });
})