class Home {
    constructor() {
        this.events();

          
    }

    events() {
        this.showBtn();
        this.showMenu();
    }
    showBtn(){
        if ($('#button-top').length > 0) {
            var btnTop = $('#button-top')
            $(window).scroll(function() {
                if ($(window).scrollTop() > 300) {
                    btnTop.addClass('show-button-top')
                } else {
                    btnTop.removeClass('show-button-top')
                }
            })
            btnTop.on('click', function(e) {
                e.preventDefault()
                $('html, body').animate({ scrollTop: 0 }, '300')
            })
        }
    }
    showMenu(){
        // $('.icon-menu-mb').click(function(){
        //     $('.content-menu-mobie').show(300);
        // })
        
        // $('.close-menu-mobie').click(function(){
        //     $('.content-menu-mobie').hide(300);
        // })
        $('.menu-close-mobie').click(function(){
            $('.content-menu-mobie').hide(300);
        })
       $('.content-menu-mobie ul li').click(function(){
        $('.content-menu-mobie').hide(300);
       })
    }
        
}
export default Home
$(document).ready(function () { 
    let width = $('.title-stroker-rotate').width()/2; 
    $('.title-stroker-rotate').css('left', -width);
    const textHeight = $('.title-stroker-rotate').outerHeight()
    if (textHeight != NaN || textHeight != undefined) {
        document.documentElement.style.setProperty('--height-title-contact-homepage', `${textHeight}px`);
    }
    const iframeHeight = $('.section-contact__wrapper-content-map-controls').outerHeight()*2.2;
    if (iframeHeight != NaN || iframeHeight != undefined) {
        document.documentElement.style.setProperty('--height-iframe-contact-homepage', `${iframeHeight}px`);
    }

    const textWidth = $('.section-contact__wrapper-content-title').outerWidth()
    if (textWidth != NaN || textWidth != undefined) {
        document.documentElement.style.setProperty('--width-title-contact-homepage', `${textWidth}px`);
    }
})

